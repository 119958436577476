html,
body {
  min-width: 1vmin;
  min-height: 1vmin;
  font-family: "Manrope", sans-serif;
  text-align: center;
}

body {
  color: #fff;
  background: #202020;
}

body.no-professional {
  color: #555;
  background: #fff;
}

.teleconsultation-videos {
  display: inline-block;
  position: relative;
}

.teleconsultation-videos .customer-video {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 90px;
  height: 130px;
  overflow: hidden;
  border-radius: 4px;
}

.teleconsultation-videos .customer-video video {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  -o-object-fit: cover;
  object-fit: cover;
}

.teleconsultation-videos .professional-video {
  max-width: 100vmin;
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
}

.teleconsultation-videos .professional-video video {
  width: 100vmin;
  min-height: 100%;
  object-fit: cover;
}

.loading {
  position: absolute;
  width: 100%;
  margin: 100px;
}

.loading .text {
  display: block;
  margin-top: 10px;
  font-size: 14px;
}

.alert {
  z-index: 9999999;
  max-width: 60%;
  margin-top: 50px;
}

.btn {
  margin-bottom: 20px;
}

.terms {
  padding: 20px;
  font-size: 0.8rem;
  text-align: left;
  color: #555;
}

.terms p {
  margin-bottom: 10px;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}

.terms ol {
  padding: 0 10px;
  counter-reset: item;
}

.terms li {
  display: block;
  margin: 5px;
}

.terms > ol > li {
  margin: 15px;
}

.terms ol > li:before {
  font-weight: 600;
  content: counters(item, ".") " - ";
  counter-increment: item;
}

.logos {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
}

.logos img {
  height: 40px;
  margin-right: 20px;
}

.controls {
  position: absolute;
  bottom: 0;
  z-index: 999999999999;
  width: 100%;
  padding: 10px 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.controls a,
.controls a:hover {
  margin-right: 30px;
  text-decoration: none;
}

.control-icon {
  width: 40px;
  height: 40px;
  padding-top: 10px;
  border: solid 2px #fff;
  border-radius: 50%;
  font-size: 17px;
  text-align: center;
  color: #fff;
}

.control-icon.disabled {
  position: relative;
  border-color: #f00;
  color: #fff;
  background: #f00;
}

.control-icon.disabled:hover {
  border-color: #c00;
  color: #fff;
  background: #c00;
}

.control-icon.disabled:hover:after {
  border-top: 2px solid #c00;
}

.control-icon.disabled:after {
  position: absolute;
  top: 16px;
  left: 8px;
  width: 20px;
  content: "";
  border-top: 2px solid red;
  transform: rotate(45deg);
  border-bottom: 2px solid white;
}

.control-icon:hover {
  color: rgba(0, 0, 0, 0.5) !important;
  background: #fff;
}

.control-icon.red {
  border-color: #f00;
  color: #f00;
}

.control-icon.red:hover {
  color: #fff !important;
  background: #f00;
}

.alert-info {
  width: 80%;
  max-width: 400px;
}
